let Treated = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 获取全部接诊信息
     * @param {any} success
     * @param {any} error
     */
    this.AllTreated = function (pageIndex,startTime,endTime,keyword,pageSize,success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/All';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyword) {
            url+=`/${keyword}`
        } else {
            url+='/%20'
        }
        if (pageSize) { 
            url += `/${pageSize}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取接诊信息
     * @param {any} success
     * @param {any} error
     */
     this.getTreated = function (state,pageIndex,startTime,endTime,keyword,success, error) {
        var pageIndex = 1;
        var url = ServiceBaseAddress + '/api/Reception/HP/State/'+state;
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyword) {
            url+=`/${keyword}`
        } else {
            url+='/%20'
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取待处理信息
     * @param {any} success
     * @param {any} error
     */
    this.WaitHandle = function (success, error) {
        var url = ServiceBaseAddress + '/api/Reception/Manager/WaitHandle';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取处理中列表信息
     * @param {any} success
     * @param {any} error
     */
    this.Handling = function (success, error) {
        var url = ServiceBaseAddress + '/api/Reception/Manager/Handling';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取已完成列表信息
     * @param {any} success
     * @param {any} error
     */
    this.Finish = function (success, error) {
        var url = ServiceBaseAddress + '/api/Reception/Manager/Finish';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 近期接诊
     * @param {any} success
     * @param {any} error
     */
    this.Recent = function (success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/Recent';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 接收转诊
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
    this.Receive = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/'+ referralId ;
        console.log(url);
        TokenClient.Post(url, true, { 'Content-Type': 'application/x-www-form-urlencoded' }, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 完成转诊
     * @param {any} referralId
     * @param {any} results
     * @param {any} rotation
     * @param {any} success
     * @param {any} error
     */
    this.FinishReferral = function (referralId, results, rotation, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/FinishReferral';
        var param = {
            referralId: referralId,
            results: results,
            rotation: rotation,
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.FinishReferral2 = function (referralId, results, rotation, rotationHosptialId, rotationDoctorId, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/FinishReferral';
        var param = {
            referralId: referralId,
            results: results,
            rotation: rotation,
            rotationHosptialId: rotationHosptialId,
            rotationDoctorId: rotationDoctorId,
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 转出
     * @param {any} referralId
     * @param {any} severity
     * @param {any} reason
     * @param {any} reasonDetails
     * @param {any} receptionDoctorId
     * @param {any} success
     * @param {any} error
     */
    this.Transfer = function (referralId, severity, reason, reasonDetails, receptionDoctorId, receptionHospitalId, HospitalPhone, HospitalName, DepartmentName, DoctorPhone, DoctorName,success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/Transfer';
        var param = {
            referralId: referralId,
            severity: severity,
            reason: reason,
            reasonDetails: reasonDetails,
            receptionDoctorId: receptionDoctorId,
            receptionHospitalId: receptionHospitalId,
            receptionHospitalPhone: HospitalPhone,
            receptionHospitalName: HospitalName,
            receptionDermant: DepartmentName,
            receptionPhone: DoctorPhone,
            receptionName: DoctorName,
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.OtherTransfer = function (referralId, severity, reason, reasonDetails, handleState, rotationName, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/Transfer';
        var param = {
            referralId: referralId,
            severity: severity,
            reason: reason,
            reasonDetails: reasonDetails,
            handleState: handleState,
            rotationName: rotationName,
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取接诊详情
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
     this.refeDetail = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/' + referralId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取时间轴
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
     this.TimeLine = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/TimeLine/' + referralId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export { Treated }
