<template>
    <div class="referral">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>接诊管理</el-breadcrumb-item>
                    <el-breadcrumb-item>接诊管理</el-breadcrumb-item>
                </el-breadcrumb>
                <head-center></head-center>
            </nav>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <el-divider content-position="left">近期接诊</el-divider>
            <el-row :gutter="20">
                <el-col :span="6" v-for="(recentItem, index) in recentList.slice(0, 4)" :key="index">
                    <div class="iq-card-body">
                        <div class="list-top clearfix">
                            <div class="list-img left">
                                <img src="../../../assets/img/login_img.png" alt="">
                            </div>
                            <div class="list-mes left">
                                <h3>{{ recentItem.referralHospitalName }}</h3>
                                <p>账号：{{ recentItem.referralPhone }}</p>
                            </div>
                        </div>
                        <div class="list-bottom clearfix">
                            <p class="left">医生：{{ recentItem.referralName }}</p>
                            <p class="right">病人：{{ recentItem.name }}</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="iq-card-body" style="margin-top: 20px;">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small"
                                    placeholder="输入姓名或手机号..." @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length>0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" header="全部接诊" :name="`全部接诊_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-tabs v-model="state" @tab-click="handleClick">
                    <el-tab-pane :label="item.labelName" :name="item.state" v-for="(item, index) in tabList"
                        :key="index">
                        <el-table class="tab" :data="tableData" stripe style="width: 100%" @cell-click="toDetail">
                            <el-table-column prop="name" label="患者姓名" width="120">
                            </el-table-column>
                            <el-table-column prop="diagnosis" label="初步诊断">
                            </el-table-column>
                            <el-table-column label="转诊医生">
                                <template slot-scope="scope">
                                    <p>{{ scope.row.referralName }}<span v-if="scope.row.referralPhone">【{{
                                    scope.row.referralPhone
                                    }}】</span></p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="referralHospitalName" label="转诊医院">
                            </el-table-column>
                            <el-table-column prop="referralDermant" label="转诊科室">
                            </el-table-column>
                            <el-table-column prop="receptionTime" label="接诊时间">
                                <template slot-scope="scope">
                                    <p>{{ scope.row.receptionTime | timefilters }}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="state" label="状态" :formatter="TreatedState">
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="250">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state == '0'" class="warning optionBtn" 
                                        @click="ReceiveReferral(scope.$index)" >接收</span>
                                    <span class="primary optionBtn" v-if="scope.row.state != '11'" 
                                        @click="DepartmentBtn(scope.$index)" >转科室</span>
                                    <span v-if="scope.row.state != '11'" class="primary optionBtn" 
                                        @click="ReferralBtn(scope.$index)" >转出</span>
                                    <span v-if="scope.row.state == '10'" class="success optionBtn" 
                                        @click="ProcesBtn(scope.$index)">完成</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <!--    转科室    -->
        <el-dialog title="转科室" :visible.sync="departmentdialog">
            <el-form :model="departmentForm" ref="departmentForm" :rules="rules">
                <el-form-item label="是否加急" :label-width="formLabelWidth">
                    <el-switch :active-value="1" :inactive-value="0" v-model="departmentForm.urgent"
                        active-color="#13ce66">
                    </el-switch>
                </el-form-item>
                <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select v-model="departmentForm.reason" placeholder="请选择转诊原因">
                        <el-option label="技术缺乏" value="1"></el-option>
                        <el-option label="设备缺乏" value="2"></el-option>
                        <el-option label="诊断疑问" value="3"></el-option>
                        <el-option label="条件有限" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="departmentForm.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="接诊科室" :label-width="formLabelWidth" prop="department">
                    <el-cascader ref="myCascader" :key="keyValue" :options="DepartmentData"
                        v-model="departmentForm.department"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        @change="TransferDepartment"></el-cascader>
                </el-form-item>
                <el-form-item label="接诊医生" :label-width="formLabelWidth" prop="doctor">
                    <el-select v-model="departmentForm.doctor" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('departmentForm')">取 消</el-button>
                <el-button type="primary" @click="SavaDepartment('departmentForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--    转出弹框    -->
        <el-dialog title="转出至" :visible.sync="treateddialog">
            <el-form :model="treatedForm" ref="treatedForm" :rules="rules">
                <el-form-item label="是否加急" :label-width="formLabelWidth">
                    <el-switch :active-value="1" :inactive-value="0" v-model="treatedForm.urgent"
                        active-color="#13ce66">
                    </el-switch>
                </el-form-item>
                <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select v-model="treatedForm.reason" placeholder="请选择转诊原因">
                        <el-option label="技术缺乏" value="1"></el-option>
                        <el-option label="设备缺乏" value="2"></el-option>
                        <el-option label="诊断疑问" value="3"></el-option>
                        <el-option label="条件有限" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="" :label-width="formLabelWidth">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="treatedForm.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="转出至" :label-width="formLabelWidth" prop="radio">
                    <el-radio-group v-model="treatedForm.radio" @change="changeRadio">
                        <el-radio :label="1">其他医院</el-radio>
                        <el-radio :label="0">合作医院</el-radio>
                    </el-radio-group>

                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 1" label="医院名称" :label-width="formLabelWidth"
                    prop="otherHospital">
                    <el-input v-model="treatedForm.otherHospital" placeholder="请输入医院名称"></el-input>
                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 0" label="接诊医院" :label-width="formLabelWidth"
                    prop="hospitalId">
                    <el-select v-model="treatedForm.hospitalId" placeholder="请选择接诊医院" @change="OtherHospital">
                        <el-option v-for="item in HospitalData" :label="item.name" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 0" label="接诊科室" :label-width="formLabelWidth"
                    prop="departmentId">
                    <el-cascader ref="cascader" :options="DepartmentData" v-model="treatedForm.departmentId"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        @change="OtherDepartment"></el-cascader>
                </el-form-item>
                <el-form-item v-if="treatedForm.radio == 0" label="接诊医生" :label-width="formLabelWidth" prop="doctorId">
                    <el-select v-model="treatedForm.doctorId" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id" :key="item.index">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('treatedForm')">取 消</el-button>
                <el-button type="primary" @click="SavaTransfer('treatedForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--    处理弹框    -->
        <el-dialog title="处理" :visible.sync="procesdialog">
            <el-form :model="procesForm" ref="procesForm" :rules="rules">
                <el-form-item label="患者处理结果" :label-width="formLabelWidth" prop="content">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="procesForm.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="患者返回至" :label-width="formLabelWidth" prop="radio">
                    <el-radio v-model="procesForm.radio" label="1">原转诊医院</el-radio>
                    <el-radio v-model="procesForm.radio" label="2">其他医院</el-radio>
                </el-form-item>
                <el-form-item v-if="procesForm.radio == 2" label="接诊医院" :label-width="formLabelWidth" prop="hospital">
                    <el-select v-model="procesForm.hospital" placeholder="请选择接诊医院" @change="selectHospital">
                        <el-option v-for="item in HospitalData" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="procesForm.radio == 2" label="接诊科室" :label-width="formLabelWidth" prop="department">
                    <el-cascader :options="DepartmentData"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        v-model="procesForm.department" @change="selectDepartment"></el-cascader>
                </el-form-item>
                <el-form-item v-if="procesForm.radio == 2" label="接诊医生" :label-width="formLabelWidth" prop="doctor">
                    <el-select v-model="procesForm.doctor" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('procesForm')">取 消</el-button>
                <el-button type="primary" @click="SaveProcesForm('procesForm')">确 定</el-button>
            </div>
        </el-dialog>
        <!--    新增接诊    -->
        <el-drawer title="新增接诊患者" :visible.sync="AddTreatedDrawer" direction="ttb" :size=size>
            <div class="addreferral">
                <el-form ref="AddTreatedform" :model="AddTreatedform" label-width="100px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="AddTreatedform.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="性别" prop="sex">
                                <el-input v-model="AddTreatedform.sex"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="年龄" prop="age">
                                <el-input v-model="AddTreatedform.age"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="tel">
                                <el-input v-model="AddTreatedform.tel"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="病情程度" prop="degree">
                                <el-select v-model="AddTreatedform.degree" placeholder="请选择病情程度" style="width: 100%">
                                    <el-option label="轻度" value="1"></el-option>
                                    <el-option label="中度" value="2"></el-option>
                                    <el-option label="重度" value="3"></el-option>
                                    <el-option label="严重" value="4"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="身份证号" prop="idcard">
                                <el-input v-model="AddTreatedform.idcard"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="初步诊断" prop="diagnosis">
                                <el-input v-model="AddTreatedform.diagnosis"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="患者介绍">
                                <quill-editor :content="AddTreatedform.content" :options="editorOption"
                                    @change="onEditorChange($event)" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--<tinymce-editor ref="editor" v-model="value"></tinymce-editor>-->
                    <el-form-item>
                        <el-button type="primary">保存</el-button>
                        <el-button>取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
        <el-drawer title="详情" :visible.sync="DetailDrawer" direction="rtl" size="550px">
            <div class="drawer-box">
                <div class="detail-item">
                    <h5 class="detail-tit">患者信息</h5>
                    <el-descriptions title="" :column="2" border size="medium">
                        <el-descriptions-item label="姓名">{{ dataDetail.name }}</el-descriptions-item>
                        <el-descriptions-item label="年龄">{{ dataDetail.age || '-'}}</el-descriptions-item>
                        <el-descriptions-item label="性别">{{ dataDetail.sex==0?'男':'女' }}</el-descriptions-item>
                        <el-descriptions-item label="手机号">{{ dataDetail.phone }}</el-descriptions-item>
                        <el-descriptions-item label="身份证号">{{ dataDetail.identity || '-' }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="" :column="1" border size="medium">
                        <el-descriptions-item label="初步诊断" :span="2">{{ dataDetail.diagnosis }}</el-descriptions-item>
                        <el-descriptions-item label="患者介绍" :span="2"><i v-html="dataDetail.introduction"></i>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="detail-item">
                    <h5 class="detail-tit">时间轴</h5>
                    <div class="block">
                        <el-timeline :reverse="true">
                            <el-timeline-item v-for="(activity, index) in timelines" :key="index" :icon="activity.icon"
                                :type="activity.type" color="#5c83f2" :size="activity.size">
                                {{ activity.receptionTime | timefilters }}
                                <el-card>
                                    <h4>{{ activity.remark }}</h4>
                                    <p>{{ activity.handHospitalName }}——{{ activity.handDermantName }}</p>
                                    <p>医生：{{ activity.handler }}</p>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                        <div class="no-data" v-show="timelines == ''">
                            <!-- <img src="../../assets/img/no-data1.png" alt=""> -->
                            <p>暂无时间轴信息</p>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { Treated } from '../../../components/Referral/Treated';
import { Organization } from '../../../components/HospitalDomain/Organization';
import { User } from '../../../components/HospitalDomain/User';
export default {
    name: "Treated",
    //注册局部过滤器
    filters: {
        timefilters(val) {
            if (val == null || val == "") {
                return "暂无时间";
            } else {
                let d = new Date(val);   //val 为表格内取到的后台时间
                let month =
                    d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
                let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
                let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
                let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
                let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
                let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
                return times;
            }
        }
    },
    data() {
        var treated = new Treated(this.TokenClient, this.Services.Referral);
        var organization = new Organization(this.TokenClient, this.Services.Authorization);
        var user = new User(this.TokenClient, this.Services.Authorization);
        return {
            Treated: treated,
            Organization: organization,
            User: user,
            recentList: [],
            state: '99',
            timelines: [],
            DetailDrawer: false,
            dataDetail: {},
            num: 0,
            datepicker: '',
            refeDetail: {},
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            tableData: [],
            HospitalData: [],
            DepartmentData: [],
            DoctorData: [],
            recentList: [],
            keyValue: 0,
            treateddialog: false,
            departmentdialog: false,
            procesdialog: false,
            AddTreatedDrawer: false,
            size: '100%',
            departmentForm: {
                id: 0,
                urgent: false,
                reason: '',
                content: '',
                hospitalId: '',
                department: '',
                doctor: '',
            },
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            treatedForm: {
                id: 0,
                urgent: false,
                reason: '',
                content: '',
                radio: '1',
                otherHospital: '',
                hospitalId: '',
                departmentId: '',
                doctorId: '',
            },
            treatedFormDepartment: '',
            procesForm: {
                id: 0,
                content: '',
                radio: '1',
                hospital: '',
                department: '',
                doctor: '',
            },
            departmentName: '',
            AddTreatedform: {
                name: '',
                sex: '',
                age: '',
                tel: '',
                degree: '',
                idcard: '',
                diagnosis: '',
                content: '',
            },
            editorOption: {
                placeholder: '编辑文章内容'
            },
            formLabelWidth: '120px',
            tabList: [
                {
                    labelName: "全部接诊",
                    state: '99'
                }, {
                    labelName: "待处理",
                    state: '0'
                }, {
                    labelName: "处理中",
                    state: '10'
                }, {
                    labelName: "已完成",
                    state: '11'
                },
            ],
            rules: {
                reason: [
                    { required: true, message: '请选择转诊原因', trigger: 'blur' }
                ],
                hospital: [
                    { required: true, message: '请选择医院', trigger: 'blur' }
                ],
                department: [
                    { required: true, message: '请选择科室', trigger: 'blur' }
                ],
                doctor: [
                    { required: true, message: '请选择医生', trigger: 'blur' }
                ],
                hospitalId: [
                    { required: true, message: '请选择医院', trigger: 'blur' }
                ],
                departmentId: [
                    { required: true, message: '请选择科室', trigger: 'blur' }
                ],
                doctorId: [
                    { required: true, message: '请选择医生', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请输入内容', trigger: 'blur' }
                ],
                radio: [
                    { required: true, message: '选择转出至', trigger: 'blur' }
                ],
                otherHospital: [
                    { required: true, message: '请输入医院名称', trigger: 'blur' }
                ],
            },
            json_fields: {
                "患者姓名": 'name',
                "患者手机号": 'phone',
                "初步诊断": 'diagnosis',
                "病情": {
                    filed: 'degree',
                    callback: val => {
                        var str = ''
                        var val = val.degree
                        switch (val) {
                            case '1':
                                str = '轻度'
                                break;
                            case '2':
                                str = '中度'
                                break;
                            case '3':
                                str = '重度'
                                break;
                            case '4':
                                str = '严重'
                                break;
                        }
                        return str
                    }
                },
                "转诊医生": 'receptionName',
                "转诊医生手机号": 'referralPhone',
                "转诊医院": 'referralHospitalName',
                "转诊时间": {
                    filed: 'referralTime',
                    callback: (val) => {
                        var val = val.referralTime
                        return this.$fliters.timefilters(val)
                    }
                },
                "接诊医院": 'receptionHospitalName',
                "接诊科室": 'receptionDermant',
                "接诊时间": {
                    filed: 'receptionTime',
                    callback: (val) => {
                        var val = val.receptionTime
                        return this.$fliters.timefilters(val)
                    }
                },
                "状态": {
                    filed: 'state',
                    callback: val => {
                        var str = ''
                        var val = val.state
                        switch (val) {
                            case -1:
                                str = '待转诊'
                                break;
                            case 10:
                                str = '处理中'
                                break;
                            case 0:
                                str = '待处理'
                                break;
                            case 11:
                                str = '已完成'
                                break;
                        }
                        return str
                    }
                },
            },
            title: '全部接诊',
            DataCount: 0
        };
    },
    methods: {
        //患者详情
        toDetail(row, column, cell, event) {
            if (column.label != "操作") {
                this.DetailDrawer = true
                var _this = this;
                _this.Treated.refeDetail(row.referralId,
                    function (data) {
                        _this.dataDetail = data.data;
                    },
                    function (error) {
                        console.log(error);
                    });
                _this.Treated.TimeLine(row.referralId,
                    function (data) {
                        _this.timelines = data.data;
                    },
                    function (error) {
                        console.log(error);
                    });
            }

        },
        search() {
            var _this = this
            _this.PageIndex = 1;
            _this.tableData = [];
            switch (_this.state) {
                case '99':
                    _this.FindAllTreated();
                    break;
                default:
                    _this.getTreated()
                    break;
            }
        },

        ChangePage(pageIndex) {
            var _this = this
            _this.PageIndex = pageIndex;
            _this.tableData = [];
            switch (_this.state) {
                case '99':
                    _this.FindAllTreated();
                    break;
                default:
                    _this.getTreated()
                    break;
            }
        },
        //角色类型状态
        TreatedSex(row) {
            return row.sex == 1 ? '女' : '男'
        },
        //状态 对应状态码
        TreatedDegree(row) {
            if (row.degree == '1') {
                return '轻度'
            } else if (row.degree == '2') {
                return '中度';
            } else if (row.degree == '3') {
                return '重度';
            } else if (row.degree == '4') {
                return '严重';
            }
        },
        TreatedState(row) {
            if (row.state == '10') {
                return '处理中';
            } else if (row.state == '0') {
                return '待处理';
            } else if (row.state == '11') {
                return '已完成';
            }
        },
        //近期转诊
        getRecent() {
            var _this = this;
            _this.Treated.Recent(
                function (data) {
                    _this.recentList = data.data;
                },
                function (error) {
                    console.log(error);
                });
        },
        FindAllTreated() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.Treated.AllTreated(_this.PageIndex, item.startTime, item.endTime, item.keyWord, null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    if (!item.keyWord) {
                        _this.DataCount = data.data.dataTotal;
                    }
                },
                function (error) {
                    console.log(error);
                });
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this
            return new Promise((resolve, reject) => {
                var item = _this.searchForm;
                if (_this.searchForm.datepicker) {
                    item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                    item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
                } else {
                    item.startTime = "";
                    item.endTime = "";
                }
                _this.Treated.AllTreated(1, item.startTime, item.endTime, item.keyWord, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (error) {
                        console.log(error);
                        resolve('');
                    });
            })
        },
        getTreated() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.Treated.getTreated(_this.state, _this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        handleClick(tab) {
            var _this = this;
            _this.PageIndex = 1;
            _this.tableData = [];
            _this.title = tab.label
            switch (tab.name) {
                case '99':
                    _this.FindAllTreated();
                    break;
                default:
                    _this.getTreated()
                    break;
            }
        },
        TransferDepartment(data) {
            var _this = this;
            _this.departmentName = this.$refs.myCascader.getCheckedNodes()[0].label;
            _this.DoctorData = [];
            _this.User.RefeUser(_this.departmentForm.hospitalId, data, function (data) {
                _this.DoctorData = data.data;
                _this.departmentForm.doctor = '';
            }, function (err) {
                console.log(err);
            });
        },
        DepartmentBtn(index) {
            var _this = this;
            var Item = _this.tableData[index];
            _this.departmentdialog = true;
            _this.departmentForm.id = Item.referralId;
            _this.departmentForm.urgent = false;
            _this.departmentForm.reason = '';
            _this.departmentForm.content = '';
            _this.departmentForm.hospitalPhone = Item.receptionHospitalPhone;
            _this.departmentForm.hospitalId = Item.receptionHospitalId;
            _this.departmentForm.hospital = '';
            _this.departmentForm.department = '';
            _this.departmentForm.doctor = '';
            _this.Organization.DepartmentTree(_this.departmentForm.hospitalId,
                function (data) {
                    _this.DepartmentData = data.data;
                }, function (err) {
                    console.log(err);
                });
        },
        SavaDepartment(formName) {
            var _this = this;
            var item = _this.departmentForm;
            var Doctor = _this.DoctorData.find(function (x) {
                return x.id === item.doctor
            })
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.Treated.Transfer(item.id, parseInt(item.urgent), item.reason, item.content, item.doctor, item.hospitalId, _this.departmentForm.hospitalPhone, _this.departmentForm.hospital, _this.departmentName, Doctor.phone, Doctor.userName, function (data) {
                        _this.departmentdialog = false;
                        _this.PageIndex = 1;
                        _this.tableData = [];
                        switch (_this.state) {
                            case '99':
                                _this.FindAllTreated();
                                break;
                            default:
                                _this.getTreated()
                                break;
                        }
                        console.log("转科室");
                    },
                        function (err) {
                            console.log(err);
                        }
                    )
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        ReferralBtn(index) {
            var _this = this;
            var Item = _this.tableData[index];
            this.treateddialog = true;
            _this.treatedForm.id = Item.referralId;
            _this.treatedForm.urgent = false;
            _this.treatedForm.reason = '';
            _this.treatedForm.content = '';
            _this.treatedForm.radio = 1;
            _this.treatedForm.otherHospital = '';
            _this.treatedForm.hospitalId = '';
            _this.treatedForm.department = '';
            _this.treatedForm.doctor = '';

        },
        changeRadio() {
            var _this = this;
            _this.DoctorData = [];
            if (_this.treatedForm.radio == 0) {
                _this.Organization.Hospital(2,
                    function (data) {
                        _this.HospitalData = data.data;
                    }, function (err) {
                        console.log(err);
                    });
            }
        },
        OtherHospital(data) {
            var _this = this;
            _this.DepartmentData = [];
            _this.DepartmentId = data;
            _this.Organization.DepartmentTree(_this.DepartmentId, function (data) {
                _this.DepartmentData = data.data;
                _this.treatedForm.departmentId = '';
                _this.treatedForm.doctorId = '';
            }, function (err) { console.log(err); });
            return _this.DepartmentId;
        },
        OtherDepartment(data) {
            var _this = this;
            _this.departmentName = this.$refs.cascader.getCheckedNodes()[0].label;
            _this.DoctorData = [];
            var doctorId = data;
            _this.User.RefeUser(_this.DepartmentId, doctorId, function (data) {
                _this.DoctorData = data.data;
                _this.treatedForm.doctorId = '';
            }, function (err) {
                console.log(err);
            });
        },
        SavaTransfer(formName) {
            var _this = this;
            _this.PageIndex = 1;
            _this.tableData = [];
            var item = _this.treatedForm;
            var Hospital = _this.HospitalData.find(function (x) {
                return x.id === item.hospitalId
            });
            var Doctor = _this.DoctorData.find(function (x) {
                return x.id === item.doctorId
            })
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.treatedForm.radio == 0) {
                        _this.Treated.Transfer(item.id, item.urgent, item.reason, item.content, item.doctorId, item.hospitalId, Hospital.phone, Hospital.name, _this.departmentName, Doctor.phone, Doctor.userName, function (data) {
                            _this.treateddialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    } else if ((_this.treatedForm.radio == 1)) {
                        _this.Treated.OtherTransfer(item.id, item.urgent, item.reason, item.content, item.radio, item.otherHospital, function (data) {
                            _this.treateddialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        selectHospital(data) {
            this.procesForm.hospital = data;
            var _this = this;
            _this.Organization.DepartmentTree(data, function (data) {
                _this.DepartmentData = data.data;

            }, function (err) { console.log(err); });
        },
        selectDepartment(data) {
            var _this = this;
            _this.User.RefeUser(this.procesForm.hospital, data, function (data) {
                _this.DoctorData = data.data;
            }, function (err) {
                console.log(err);
            });
        },
        ProcesBtn(index) {
            var _this = this;
            var Item = _this.tableData[index];
            _this.procesdialog = true;
            _this.procesForm.id = Item.referralId;
            _this.procesForm.content = '';
            _this.procesForm.radio = '';
            _this.procesForm.hospital = '';
            _this.procesForm.department = '';
            _this.procesForm.doctor = '';
            _this.Organization.RotaHospital(Item.receptionHospitalId,
                function (data) {
                    _this.HospitalData = data.data;
                }, function (err) {
                    console.log(err);
                });
        },
        SaveProcesForm(formName) {
            var _this = this;
            _this.PageIndex = 1;
            _this.tableData = [];
            var item = _this.procesForm
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (_this.procesForm.radio == 2) {
                        _this.Treated.FinishReferral2(item.id, item.content, parseInt(item.radio), item.hospital, item.doctor, function (data) {
                            _this.procesdialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    } else {
                        _this.Treated.FinishReferral(item.id, item.content, parseInt(item.radio), function (data) {
                            _this.procesdialog = false;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllTreated();
                                    break;
                                default:
                                    _this.getTreated()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        AddReferralBtn() {
            this.AddTreatedDrawer = true;
        },
        //接收
        ReceiveReferral(index) {
            var _this = this;
            var receiveItem = _this.tableData[index];
            this.$confirm('确定接收此条转诊吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.PageIndex = 1;
                _this.tableData = [];
                _this.Treated.Receive(receiveItem.referralId, function (data) {
                    switch (_this.state) {
                        case '99':
                            _this.FindAllTreated();
                            break;
                        default:
                            _this.getTreated()
                            break;
                    }
                    _this.$message({
                        type: 'success',
                        message: '接收成功!'
                    });
                }, function (err) {
                    console.log(err);
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消接收'
                });
            });
        },
        cancelDialog(formName) {
            var _this = this;
            _this.treateddialog = false;
            _this.procesdialog = false;
            _this.departmentdialog = false;
            this.$refs[formName].resetFields();
        },
        onEditorChange({ editor, html, text }) {
            this.content = html;
        },
    },
    watch: {
        treatedFormDepartment() {
            if (this.$refs.cascader) {
                this.$refs.cascader.dropDownVisible = false
            }
        }
    },
    mounted() {
        var _this = this;
        _this.FindAllTreated();
        _this.getRecent();
    }
}
</script>

<style scoped>
/*content*/

.list-img {
    margin-right: 15px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #e5e5e5;
}

.list-img img {
    width: 100%;
    height: 100%;
}

.list-mes {
    padding: 10px 0;
}

.list-bottom {
    margin-top: 15px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #cbdbf7;
    line-height: 1.8;
}

.addreferral {
    width: 80%;
    margin: 0 auto;
}

.addreferral .el-form {
    width: 100%;
}

.drawer-box {
    padding: 0 30px;
}

.tab /deep/ .el-table__body-wrapper {
    cursor: pointer;
}

.pro-box {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f7fa;
}

.item-img {
    float: left;
    width: 118px;
    height: 118px;
    margin-right: 10px;
    overflow: hidden;
}

.item-img img {
    width: 100%;
}

.item-mes {
    width: calc(100% - 130px);
    float: left;
    position: relative;
}

.item-tit {
    color: #454545;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 57px;
}

.item-price {
    margin-top: 40px;
}

.shopcart {
    position: absolute;
    right: 0;
    bottom: 0;
}

.detail-item {
    margin-bottom: 10px;
}

.detail-item .detail-tit {
    font-size: 16px;
}

.detail-item>p {
    margin: 5px 0;
}

.detail-item>p span {
    display: inline-block;
    width: 90px;
    text-align: left;
}
</style>
<style>
.el-select-dropdown__wrap,
.el-cascader-menu__wrap {
    margin-bottom: 0 !important;
}

.ql-snow .ql-picker-label::before {
    vertical-align: top;
}

.ql-container {
    max-height: 250px;
    overflow-y: auto;
}

.el-divider__text {
    background-color: #eff7f8;
}

.el-date-editor .el-range-separator {
    width: 10%;
}
</style>
